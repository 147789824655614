import React from "react";
import { connect } from 'react-redux';
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import PropTypes from "prop-types";
import { Menu, MenuItem, ListItemIcon } from "@material-ui/core";
import GradeIcon from '@material-ui/icons/Grade';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import LanguageIcon from '@material-ui/icons/Language';
import texts from "../texts/texts.json"
import * as actions from '../store/actions';

const textos = texts.menuATV;

const styles = theme => ({
  divFlex: {
    display: "flex !important",
    marginRight: "4px !important"
  },
  buttonRoot: {
    boxShadow: "none !important",
  },
  iconButton: {
    marginLeft: "4px !important",
    marginRight: "-0.2em !important",
    width: "30px !important",
    height: "30px !important",
    padding: "9px !important",
    color: "#777 !important",
    "&:disabled": {color: "rgb(222, 225, 229) !important" },
    marginTop: "50% !important",
    marginBottom: "50% !important",
    backgroundImage: "none !important",
    background: "none !important"
  },
  menuClass: {
    display: "flex !important",
    justifyContent: "center !important",
    margin: "auto !important",
    borderRadius: "0.8em !important",
    zIndex: "99999!important"
  },
  menuItem: {
    fontSize: "13px !important"
  },
  icon: {
    minWidth: "36px !important",
  },
  iconDescarga: {
    minWidth: "33px !important",
    marginLeft: "3px !important"
  },
  icons: {
    fontSize: "20px !important"
  }
});

class MenuATV extends React.Component {
  state = {
    anchorEl: null
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  changeLanguage() {
    if (this.props.selected_language === "va") {
      this.props.updateLanguage("es");
    } else {
      this.props.updateLanguage("va");
    }
    this.setState({ anchorEl: null });
  }

  handleValoracion = () => {
    this.props.updateScreen("valoracion");
    this.setState({ anchorEl: null });
  };

  handleProtecdatos = () => {
    this.props.updateScreen("protecdatos");
    this.setState({ anchorEl: null });
  }

  render() {
    const { classes } = this.props;
    const { anchorEl } = this.state;

    return (
      <div className={classes.divFlex}>
        <IconButton
          className={classes.iconButton}
          aria-owns={anchorEl ? "simple-menu" : undefined}
          aria-haspopup="true"
          onClick={this.handleClick}
          disabled={this.props.is_writing}
        >
          <MoreVertIcon className={classes.icons} />
        </IconButton>
        <Menu
          className={classes.menuClass}
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
          PaperProps={{
            style: {
              borderRadius: "1em"
            }
          }}
        >
          <MenuItem
            className={classes.menuItem}
            onClick={this.changeLanguage.bind(this)}
          >
            <ListItemIcon className={classes.icon}>
              <LanguageIcon color="primary" className={classes.icons} />
            </ListItemIcon>
            {textos.labels[this.props.selected_language].cambiarLengua}
          </MenuItem>
          <MenuItem
            className={classes.menuItem}
            onClick={this.handleValoracion.bind(this)}
          >
            <ListItemIcon className={classes.icon}>
              <GradeIcon color="primary" className={classes.icons} />
            </ListItemIcon>
            {textos.labels[this.props.selected_language].valoracion}
          </MenuItem>
          <MenuItem
            className={classes.menuItem}
            onClick={this.handleProtecdatos.bind(this)}
          >
            <ListItemIcon
              className={classes.icon}
            >
              <VerifiedUserIcon color="primary" className={classes.icons} />
            </ListItemIcon>
            {textos.labels[this.props.selected_language].protecdatos}
          </MenuItem>
        </Menu>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    selected_language: state.selected_language,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateLanguage: (language) => dispatch(actions.updateLanguage(language)),
    updateScreen: (screen) => dispatch(actions.updateScreen(screen))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MenuATV));
