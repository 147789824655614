import { connect } from 'react-redux';
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import { styled, withStyles } from "@material-ui/core/styles";
import autoscroll from "autoscroll-react";
import PropTypes from "prop-types";
import React from "react";
import ReactMarkdown from "react-markdown";
import Change620Button from './components-atv/Change620Button';
import ValorarCard from './components-atv/ValorarCard';
import DesplegableInicio from './components-atv/DesplegableInicio';
import bombilla from './images/bombilla.gif';
import manuals_es from './images/manuals_es.jpg';
import save_es from './images/save_es.jpg';
import exemptions_es from './images/exemptions_es.jpg';
import load_es from './images/load_es.jpg';
import manuals_va from './images/manuals_va.jpg';
import save_va from './images/save_va.jpg';
import exemptions_va from './images/exemptions_va.jpg';
import load_va from './images/load_va.jpg';

const styles = theme => ({
  root: {
    overflowY: "auto",
    flex: "1",
    padding: "0.5em !important"
  },
  fullMessage: {
    display: "flex !important",
    fontSize: "0.9em !important",
    maxWidth: "100% !important",
    marginRight: "1em !important",
    flexDirection: "column !important"
  },
  messageBlock: {
    display: "flex !important",
    flexDirection: "row !important",
    margin: "0.18em !important"
  },
  optionsContainer: {
    marginTop: "0.5em !important",
    marginBottom: "0.5em !important",
    display: "flex !important",
    flexWrap: "wrap !important",
    justifyContent: "space-evenly !important"
  },
  messageBubble: {
    flexDirection: "row !important",
    overflow: "hidden !important",
    alignItems: "flex-end !important",
    boxShadow: "none !important",
    paddingTop: "0.75em !important",
    paddingBottom: "0.5em !important",
  },
  // messageBubbleBlink: {
  //   flexDirection: "row !important",
  //   overflow: "hidden !important",
  //   alignItems: "flex-end !important",
  //   boxShadow: "none !important",
  //   paddingTop: "0.75em !important",
  //   paddingBottom: "0.5em !important",
  //   animation: `$blinkBack 5000ms infinite`,
  //   background: "linear-gradient(280deg, #fff, rgba(11,88,108,0.5))",
  //   backgroundSize: "3000% 3000%"
  // },
  // "@keyframes blinkBack": {
  //   // "0%, 49%": {
  //   //   backgroundColor: "#d1e0e6"
  //   // },
  //   // "50%, 100%": {
  //   //   backgroundColor: "#fff"
  //   // }
  //   "0%":{backgroundPosition:"0% 50%"},
  //   "50%":{backgroundPosition:"100% 50%"},
  //   "100%":{backgroundPosition:"0% 50%"}
  // },


  messageBubbleBlink: {
    flexDirection: "row !important",
    overflow: "hidden !important",
    alignItems: "flex-end !important",
    boxShadow: "none !important",
    paddingTop: "0.75em !important",
    paddingBottom: "0.5em !important",
    animation: `$blinkBack 5000ms infinite`,
    // borderColor: "linear-gradient(280deg, #fff, rgba(11,88,108,0.5))",
    // borderSize: "2px",
    // borderStyle: "solid",
    // backgroundSize: "3000% 3000%",
    border: "2px #ff0000 solid",
    animation: "$blinkBorder 1.5s infinite"
  },
  "@keyframes blinkBorder": { "50%": { border: "2px #dee1e5 solid" } },



  messageText: {
    whiteSpace: "pre-line !important",
    overflowWrap: "break-word !important",
    margin: "0 0.5em 0 1em !important",
    fontFamily: "Roboto, sans-serif !important",
    fontSize: "13px !important",
    wordWrap: "break-word !important",
    maxWidth: "250px !important",
    textAlign: "left !important",
    "& p": {
      margin: "0 !important",
      fontSize: "13px !important",
    },
    "& a": {
      color: theme.palette.primary.main + " !important"
    },
    "& li": {
      marginBottom: "0.4em !important",
      fontSize: "13px !important",
      listStyle:"disc !important",
      paddingLeft:"3px !important"
    },
    "& ul":{
      listStyle:"disc !important",
      paddingLeft:"30px !important"
    }
  },
  date: {
    fontFamily: "Roboto, sans-serif !important",
    margin: "0 1em !important",
    fontSize: "10px !important",
    textAlign: "right !important"
  },
  imageContainer: {
    width: "100% !important"
  },
  card: {
    maxWidth: "345 !important",
    minWidth: "200 !important"
  },
  cardSize: {
    height: "210 !important",
    backgroundColor: "#f8f8f8 !important",
    display: "flex !important",
    flexDirection: "column !important"
  },
  cardContent: {
    whiteSpace: "pre-line !important",
    overflowWrap: "break-word !important",
    paddingTop: "0.7em !important",
    paddingBottom: "0 !important",
    height: "100% !important",
    flex: "1 !important"
  },
  media: {
    height: "140 !important"
  },
  slider: {
    display: "flex !important",
    flexWrap: "wrap !important",
    padding: "4 !important",
    justifyContent: "space-around !important",
    overflow: "hidden !important",
    width: "auto !important",
    backgroundColor: theme.palette.background.paper + " !important"
  },
  gridList: {
    flexWrap: "nowrap !important",
    paddingLeft: "4 !important",
    transform: "translateZ(0) !important"
  },
  cardActionArea: {
    flex: "1 !important"
  },
  cardButtons: {
    flex: "0 !important"
  },
  cardText: {
    marginBottom: "0 !important",
    "& a": {
      color: theme.palette.primary.main + " !important"
    },
    "& li": {
      marginBottom: "0.4em !important"
    }
  },
  button: {
    width: "45% !important",
    margin: "2px !important",
    fontWeight: "600 !important",
    padding: "2px 10px !important",
    textTransform: "none !important",
    fontSize: "16px !important",
    fontFamily: "Roboto, sans-serif !important",
    borderRadius: "2%!important",
    lineHeight: "1.4em !important",
    backgroundImage: "none !important",
    textShadow: "none !important",
    borderColor: theme.palette.primary.main + " !important"
  },
  bombillaGif: {
    width: "24px",
    height: "24px",
    marginLeft: "10px",
    marginTop: "5px"
  },

  messageTextGifs: {
    whiteSpace: "pre-line !important",
    overflowWrap: "break-word !important",
    margin: "0 0.5em 0.45em 1em !important",
    fontFamily: "Roboto, sans-serif !important",
    fontSize: "13px !important",
    wordWrap: "break-word !important",
    maxWidth: "250px !important",
    textAlign: "left !important",
    "& p": {
      margin: "0 !important",
      fontSize: "13px !important",
    },
    "& a": {
      color: theme.palette.primary.main + " !important"
    },
    "& li": {
      marginBottom: "0.4em !important"
    }
  }
});

class MyList extends React.Component {

  constructor(props) {
    super(props);
    this.images = {
      manuals_es: manuals_es,
      save_es:save_es,
      load_es:load_es,
      exemptions_es:exemptions_es,
      manuals_va: manuals_va,
      save_va:save_va,
      load_va:load_va,
      exemptions_va:exemptions_va
    };
  }

  options = message => {
    if (message.type === "text_button") {
      return this.textButtons(message.data);
    } else if (message.type === "postback_button") {
      return this.postbackButtons(message.data);
    } else if (message.type === "event_button") {
      return this.eventButtons(message.data);
    } else if (message.type === "hyperlink_button") {
      return this.hyperlinkButtons(message.data);
    } else if (message.type === "quick_replies" || message.type === "quick_replies+620") {
      return this.quickReplies(message.data);
    } else if (message.type === "image") {
      return this.showImage(message);
    } else if (message.type === "aceptar") {
      return <Change620Button handleResponse={this.props.handleResponse} user={this.props.user} />;
    } else if (message.type === "valorarCard") {
      return <ValorarCard />
    } else if (message.type === "desplegable") {
      return <DesplegableInicio data={message.data} onButtonClick={(event) => this.props.onButtonClick(event.target.textContent)} />
    } else {
      return null;
    }
  };


  textButtons = message => {
    const { classes } = this.props;
    return message.map(m => {
      const key = message.key + "_" + m.text;
      return (
        <Button
          key={key}
          className={classes.button}
          color="primary"
          variant="outlined"
          onClick={(event) => this.props.onButtonClick(event.target.textContent)}
        >
          {m.text}
        </Button>
      );
    });
  };


  postbackButtons = message => {
    const { classes } = this.props;
    return message.map(m => {
      const key = message.key + "_" + m.text;
      return (
        <Button
          key={key}
          className={classes.button}
          color="primary"
          variant="outlined"
          onClick={() => this.props.onPostbackButtonClick(m.payload, m.payload)}
        >
          {m.text}
        </Button>
      );
    });
  };


  eventButtons = message => {
    const { classes } = this.props;
    return message.map(m => {
      const key = message.key + "_" + m.text;
      return (
        <Button
          key={key}
          className={classes.button}
          color="primary"
          variant="outlined"
          onClick={() => this.props.eventButtonClick(m)}
        >
          {m.text}
        </Button>
      );
    });
  };


  hyperlinkButtons = message => {

    const { classes } = this.props;
    return message.map(m => {
      const key = message.key + "_" + m.text;
      return (
        <Button
          key={key}
          className={classes.button}
          color="primary"
          variant="outlined"
          onClick={(event) =>{
            const url=window.location.href;
            let URLredirec=m.data;
            if (url== "https://atenea.ha.gva.es/sim620/home?lang=va"||url=="http://atv.gva.es/va/inici"){
              console.log("Página en valencia")
              URLredirec=URLredirec.replace('#lang#','?lang=va')
            }
            else{
              URLredirec=URLredirec.replace('#lang#','?lang=es')
            }
            window.open(URLredirec,'_blank')
            }}
        >
          {m.text}
        </Button>
      );
    });
  };

  quickReplies = (message) => {
    const { classes } = this.props;
    return (
      message.map((m) => {
        const key = message.key + "_" + m.text;
        return (
          <Button
            key={key}
            className={classes.button}
            color="primary"
            size="small"
            variant="outlined"
            onClick={() => window.open(m.data, "_blank")}
          >
            {m.text}
          </Button>
        );
      })
    );
  };


  showImage = message => {
    const { classes } = this.props;
    return message.data.map(m => {
    //Se añade el .default porque tras una actualizacion el require transforma el link en Object Module:


      const key = m.key + "_" + m.text;
      return (
        <div key={key}>
          <a href={this.images[m.data]} target="_blank" rel="noopener noreferrer">
            <img className={classes.imageContainer} src={this.images[m.data]}/>
          </a>
        </div>
      );
    });
  };

  handleUser = (isUser, property) => {
    if (isUser) {
      if (property === "colorMensaje") {
        return "#dee1e5";
      } else if (property === "colorTexto") {
        return "#263238";
      } else if (property === "borderRadiusMensaje") {
        return "1em 0em 1em 1em";
      } else if (property === "marginTopMessage") {
        return "-0.4em";
      } else if (property === "alignItems") {
        return "flex-end";
      } else if (property === "weight") {
        return "500";
      }
    } else {
      if (property === "colorMensaje") {
        return "white";
      } else if (property === "colorTexto") {
        return "#263238";
      } else if (property === "borderRadiusMensaje") {
        return "0em 1em 1em 1em";
      } else if (property === "borderMensaje") {
        return "1px solid #dee1e5";
      } else if (property === "marginTopMessage") {
        return "0em";
      }
    }
  };


  renderTextGifs(classes, msg, linkTarget, type) {
    if (type === "quick_replies+620") {
      let code = [];
      msg.split("<bombillaGif>").map((m) => {
        if (m) {
          code.push(<div style={{ display: "flex" }}>
            <img src={bombilla} className={classes.bombillaGif} alt="bombilla" />
            <ReactMarkdown
              className={classes.messageTextGifs}
              source={m}
              linkTarget={linkTarget}
            />
          </div>)

        }
      });
      return (
        <div>
          {code.map((cod) => {
            return (cod)
          })}
        </div>
      )
    } else {
        if (window.location.hostname === "pruebas.ha.gva.es"){
          msg=msg.replaceAll('•','')
        }
      return (

        <ReactMarkdown
          className={classes.messageText}
          source={msg}
          linkTarget={linkTarget}
        />
      );
    }
  }

  renderMessage(m, indice, animation) {
    if (m.text) {
      let messages = m.text.split("\n\n");
      let type = m.type;
      const { classes } = this.props;
      return messages.map((msg) => {
        let linkTarget = "_blank";
        if (m.text.includes("atenea.ha.gva.es/sim620") || m.text.includes("pruebas.ha.gva.es/sim620")) {
          linkTarget = "";
        }
        let clase = classes.messageBubble;
        if (type === "quick_replies+620") {
          clase = classes.messageBubbleBlink;
          // animation = {};
        }
        return (
          <div key={indice + "_" + m.text} className={classes.messageBlock}>
            <Paper
              className={clase}
              style={{
                backgroundColor: this.handleUser(m.isUser, "colorMensaje"),
                color: this.handleUser(m.isUser, "colorTexto"),
                borderRadius: this.handleUser(m.isUser, "borderRadiusMensaje"),
                border: type !== "quick_replies+620" ? this.handleUser(m.isUser, "borderMensaje") : "",
                fontWeight: this.handleUser(m.isUser, "weight"),
                ...animation
              }}
            >
              {this.renderTextGifs(classes, msg, linkTarget, type)}
              <p
                className={classes.date}
                style={{ color: this.handleUser(m.isUser, "colorTexto") }}
              >
                {m.date}
              </p>
            </Paper>
          </div>
        );
      });
    } else {
      return null;
    }
  }


  shouldComponentUpdate(nextProps) {
    if (
      nextProps.messages === this.props.messages &&
      nextProps.text !== this.props.text
    ) {
      return false;
    } else {
      return true;
    }
  }


  render() {
    const { classes } = this.props;
    return (
      <div style={{ flex: "1 !important" }} className={classes.root}>
        {this.props.messages.map((m, index) => {
          let animation = {};
          if (index === (this.props.messages.length - 1) && !m.isUser) {
            animation = {
              animationName: "fadeInOut, fadeIn",
              animationDuration: "2.5s"
            }
          }
          return (
            <div
              className={classes.fullMessage}
              style={{ alignItems: this.handleUser(m.isUser, "alignItems") }}
              key={m.text + "_" + index}
            >
              {this.renderMessage(m, index, animation)}
              {this.options(m) ? (
                <div className={classes.optionsContainer} style={{ ...animation }} >
                  {this.options(m)}
                </div>
              ) : null}
            </div>
          );
        })}
      </div>
    );
  }
}

MyList.propTypes = {
  classes: PropTypes.object.isRequired
};


const mapStateToProps = state => {
  return {
    messages: state.messages
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(autoscroll(MyList)));
