import React from 'react';
import { connect } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import locale from '../texts/locale.json';
import SendButton from './SendButton.js';
import MenuATV from '../components-atv/MenuATV';


const styles = theme => ({
  container: {
    display: "contents !important",
    flexWrap: "wrap !important",
    width: "100% !important",
    height: "20% !important"
  },

  textField: {
    heigth:"200px !important",
    marginTop: "auto",
    marginBottom: "auto",
    width: "100% !important",
    padding: "0 !important",
    flex: "1 !important",
    "& fieldset": { border: "0 !important" },
    boxShadow: "none !important",
    "&:focus": {
      border: "none !important",
      background: "none !important"
    },

  },
  button: {
    margin: theme.spacing(1),
    display: "flex !important",
    justifyContent: "flex-start !important",
    textTransform: "none !important",
    textAlign: "left !important",
    width: "100% !important",
    color: "black !important",
    padding: "0 !important",
    "& span": {},
    "&:hover": { backgroundColor: "rgb(244, 251, 255) !important" }
  },
  sendButton: {
    display: "flex !important",
    justifyContent: "center !important",
    margin: "auto !important",
    color: "white !important",
    backgroundColor: "royalblue !important",
    transition: "background-color 0.1s !important",
    "&:disabled": { backgroundColor: "light-grey !important", color: "white !important" },
    "&:hover": {
      backgroundColor: "rgb(164, 174, 232) !important"
    }
  },
  paper: {
    display: "flex !important",
    flex: "1 !important",
    zIndex: "1 !important",
    maxHeight: "150px !important",
    overflowY: "auto !important",
    width: '75% !important',
    borderRadius: "2em !important",
    marginRight: "0.6em !important"

  },
  normalInput: {
    marginTop: "1% !important",
    marginBottom: "0.6em !important",
    marginLeft: "0.6em !important",
    marginRight: "0.6em !important",
    display: "flex !important",

  },

  input: {
    fontSize:"16px !important",
    boxShadow: "none !important",
    "&:focus": {
      border: "none !important",
      background: "none !important"
    },
    fontFamily: "Roboto, sans-serif !important",
  },
  chipContainer: {
    margin: "0.5em 0 0 1em !important"

  }
});


class MyTextInput extends React.Component {

  state = {
    height: 0,
    disabled: true
  };


  handleForm(event) {
    event.preventDefault();
    this.setState({ disabled: true });
    this.props.onSend(this.props.text.replace(/^\s+|\s+$/g, ""));
  }


  _handleKeyPress = e => {
    if (e.key === "Enter" && this.props.text.replace(/^\s+|\s+$/g, "") !== "") {
      this.handleForm(e);
    }
  };


  handleChange = event => {
    let value = event.target.value;
    if (value.replace(/^\s+|\s+$/g, "")) {
      this.setState({ disabled: false });
    } else {
      this.setState({ disabled: true });
    }
    this.props.onChange(value);
  };


  disableButton = (text) => {
    return text.replace(/^\s+|\s+$/g, "").length > 0 || !this.state.disabled ? false : true
  }


  getTextArea() {
    if (this.props.selected_language) {
      return locale[this.props.selected_language].textArea
    }
  }


  render() {
    const { classes } = this.props;
    return (
      <form
        className={classes.container}
        noValidate
        autoComplete="off"
        onSubmit={this.handleForm.bind(this)}
        value={this.state.value}
      >
        <div
          ref={textInput => (this.textInput = textInput)}
          className={classes.normalInput}
        >
          <Paper className={classes.paper}>
            <MenuATV is_writing={this.props.writing} />
            <TextField
              disabled={this.props.disable}
              id="outlined-text"
              placeholder={this.getTextArea()}
              className={classes.textField}
              rowsMax={5}
              multiline
              margin="dense"
              variant="outlined"
              value={this.props.text}
              onChange={this.handleChange}
              onKeyPress={this._handleKeyPress}

              InputProps={{
                className: classes.input,
              }}
              inputProps={{
                className: classes.input,
              }}
            />
          </Paper>
          <div style={{ display: "flex" }}>
            <SendButton disabled={this.disableButton(this.props.text.trimLeft())} />
          </div>
        </div>
      </form>
    );
  }
}

MyTextInput.propTypes = {
  classes: PropTypes.object.isRequired
};


const mapStateToProps = state => {
  return {
    selected_language: state.selected_language,
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MyTextInput));
