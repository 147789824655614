import React from "react";
import { connect } from 'react-redux';
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import texts from "../texts/texts.json"
import * as actions from '../store/actions';
import { languageCall, getLanguageUser, eventsCall } from "../ChatbotQueries";
import { messagesStored } from "../Session";

const textos = texts.initProtecdatos;
const PRINCIPAL_PATHNAMES = ["pre-atv.gva.es", "atv.gva.es"];

const styles = theme => ({
  container: {
    display: "flex !important",
    flexDirection: "column !important",
    paddingRight: "4% !important",
    paddingLeft: "4% !important",
    paddingBottom: "4% !important",
    marginBottom: "4px !important",
    overflowY: "scroll !important"
  },
  titles: {
    fontSize: "12px !important",
    fontWeight: "600 !important",
    marginTop: "3% !important"
  },
  textResponsable: {
    fontSize: "11px !important",
    marginTop: "5px !important",
    lineHeight: "12px !important",
    textAlign: "justify !important"
  },
  text: {
    fontSize: "11px !important",
    marginTop: "5px !important",
    textAlign: "justify !important"
  },
  enlace: {
    fontSize: "11px !important",
    marginTop: "5px !important"
  },
  button: {
    marginLeft: "auto !important",
    marginRight: "auto !important",
    marginTop: "10px !important",
    width: "48% !important",
    fontWeight: "600 !important",
    padding: "2px 10px !important",
    textTransform: "none !important",
    fontSize: "0.9em !important",
    "&:hover": {
      backgroundColor: theme.palette.secondary.dark + " !important",
      color: "white !important"
    },
    borderRadius: "2% !important",
    backgroundImage: "none !important",
    textShadow: "none !important"
  }
});

class InitProtecdatos extends React.Component {
  constructor(props){
    super(props)
    this.state={
      deshabilitado:true
    }
  }
  async handleStart(user) {
    let lastMessages = messagesStored();
    this.props.updateMessages([]);
    await languageCall(user, this.props.selected_language);
    let intentos = 0;
    while (this.props.selected_language !== await getLanguageUser(user) && intentos < 3) {
      await languageCall(user, this.props.selected_language);
      intentos++;
    }
    const event_id = (PRINCIPAL_PATHNAMES.includes(window.location.hostname) || window.location.href === "http://localhost:3000/") ? "welcome_message" : "welcome_message_620";
    let data = {};
    if (event_id === "welcome_message") {
      let env = "pre";
      if (window.location.hostname === "localhost") {
        if (window.location.port === "3000") {
          env = "pro";
        }
      } else if (window.location.hostname === "atv.gva.es" || window.location.hostname === "atenea.ha.gva.es") {
        env = "pro";
      }
      data = { env: env };
    }
    //console.log("Envío de evento en Init")
    let response = await eventsCall(
      { event_id: event_id, user_id: user, data: data },
      this.props.selected_language
    );
    for (let i = 0; i < lastMessages.length; i++) {
      if (lastMessages[i].text) {
        lastMessages[i].answer = lastMessages[i].text;
      }
    }
    this.props.handleResponse(response.concat(lastMessages));
  }
  componentDidMount(){
    //console.log("en el component")
    this.deshabilitado = setInterval(() => this.setState({deshabilitado:false }), 1500);
    console.log(this.state.deshabilitado)
  }

  handleChat() {
    // storeItem("proteccionDatos", true);
    this.setCookie('protecDatosCookie', true, 2)
    this.handleStart(this.props.user);
    this.props.updateScreen("chat");
  }

  setCookie = function (cname, cvalue, exhours) {
    var d = new Date();
    d.setTime(d.getTime() + (exhours * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    //console.log(`El domain: ${window.location.hostname}`)
    document.cookie = cname + "=" + cvalue + ";" + expires + ";domain=.gva.es;path=/;";
    //document.cookie = cname + "=" + cvalue + ";" + expires + ";domain=localhost;path=/620;";
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.container} >
        <Typography className={classes.titles} color="inherit" component="p">
          {textos.titleResponsable[this.props.selected_language]}
        </Typography>
        <Typography className={classes.textResponsable} color="inherit" component="p">
          {textos.textoResponsable1[this.props.selected_language]}
        </Typography>
        <Typography className={classes.textResponsable} color="inherit" component="p">
          {textos.textoResponsable2[this.props.selected_language]}
        </Typography>
        <Typography className={classes.textResponsable} color="inherit" component="p">
          {textos.textoResponsable3[this.props.selected_language]}
        </Typography>
        <Typography className={classes.titles} color="inherit" component="p">
          {textos.titleFinalidades[this.props.selected_language]}
        </Typography>
        <Typography className={classes.text} color="inherit" component="p">
          {textos.textoFinalidades[this.props.selected_language]}
        </Typography>
        <Typography className={classes.titles} color="inherit" component="p">
          {textos.titleEjercicio[this.props.selected_language]}
        </Typography>
        <Typography className={classes.text} color="inherit" component="p">
          {textos.textoEjercicio[this.props.selected_language]}
        </Typography>
        <a className={classes.enlace} href={"http://atv.gva.es/" + this.props.selected_language + "/pd"} target="_blank">
          {textos.textoEnlace[this.props.selected_language]}
        </a>
        <Button
          disabled={this.state.deshabilitado}
          className={classes.button}
          color="primary"
          variant="contained"
          onClick={this.handleChat.bind(this)}
        >
          Continuar
        </Button>
      </div>
    );
  }
}


const mapStateToProps = state => {
  return {
    selected_language: state.selected_language,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateScreen: (screen) => dispatch(actions.updateScreen(screen)),
    updateMessages: (messages) => dispatch(actions.updateMessages(messages))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(InitProtecdatos));
