import validation from './texts/validation.json'
export const formulario = {
  'comprador': {
    'nifComprador': validatorNifNieCif,
    'inputParticipacion': validatorParticipacion
  },
  'vendedor': {
    'nifVendedor': validatorNifNieCif,
  },
  'presentador': {
    'nifPresentador': validatorNifNieCif,
  },
  'caractecnicas': {
    'matriculacoche': validatorMaticrula,
    'matriculamoto': validatorMaticrula,
    'matriculaBarco': validarMatriculaEmbarcacion
  }
}

const name_tabs = {
  'comprador': {"es": '**Comprador**', "va": '**Comprador**'},
  'vendedor': {"es": '**Vendedor**', "va": '**Venedor**'},
  'presentador': {"es": '**Presentador**', "va": '**Presentador**'},
  'caractecnicas': {"es": "**Caracteristicas Técnicas**", "va": '**Característiques Tècniques**'}
}

const patterns = {
  'num_nif': /(^|\s|\.|,|:)(\d{7,8})(\s|-|\.)?[trwagmyfpdxbnjzsqvhlckeTRWAGMYFPDXBNJZSQVHLCKE](\.|,|\s|$)/,
  'num_nie': /((^|\s|\.|,|:)(([XYZxyz])(\s|-|\.)?)(\d{7,8})(\s|-|\.)?([A-Za-z]))(\.|,|\s|$)/,
  'num_cif': /(^|\s|\.|:|,)(([ABCDEFGHJKLMNPQRSUVWabcdefghjklmnpqrsuvw])(\s|-|\.)?(\d{7})(\s|-|\.)?([0-9A-Ja-j]))(\s|\.|,|$)/,
  'matricula_nueva': /^\d{4}([b-dB-D]|[f-hF-H]|[j-nJ-N]|[p-tP-T]|[v-zV-Z]){3}$/,
  'matricula_vieja': /(^[a-zA-Z]{1,2})\d{4}([a-zA-Z]){1,2}$/,
  'matricula_1971': /(^[a-zA-Z]{1,2})\d{4}$/,
  'matricula_embarcacion': /(^\d{1})[a-zA-Z]{1,2}\d{4,6}$/
}

// VALIDACIONES
function validatorNifNieCif(value, tab) {
  let response = null
  console.log(value);
  if (value) {
    if (value.length !== 9) {
      response = Object.assign({}, validation[tab]['nif_longitud']);
      response["answer"] = response[window.Chat.props.selected_language].replace('##tab##', name_tabs[tab][window.Chat.props.selected_language])
      response["answer"] = response["answer"].replace('##value##', '*' + value + '*')
      return response
    }
    let valid = patterns["num_nif"].test(value)
    if (!valid) {
      valid = patterns["num_nie"].test(value)
      if (!valid) {
        valid = patterns["num_cif"].test(value)
        if (!valid) {
          response = Object.assign({}, validation[tab]['nif_invalido']);
          response["answer"] = response[window.Chat.props.selected_language].replace('##tab##', name_tabs[tab][window.Chat.props.selected_language])
          response["answer"] = response["answer"].replace('##value##', '*' + value + '*')
        }
      }
    }
  }
  return response
}

function validatorParticipacion(value, tab) {
  let response = null
  if (value) {
    if (value < 50 || value > 100) {
      response = Object.assign({}, validation[tab]["participación_invalido"]);
      response["answer"] = response[window.Chat.props.selected_language].replace('##tab##', name_tabs[tab][window.Chat.props.selected_language])
      response["answer"] = response["answer"].replace('##value##', '*' + value + '*')
    }
  }
  return response
}

function validatorMaticrula(value, tab) {
  let response = null
  if (value) {
    console.log(value);
    let valid = patterns["matricula_nueva"].test(value)
    if (!valid) {
      valid = patterns["matricula_vieja"].test(value)
      if (!valid) {
        valid = patterns["matricula_1971"].test(value)
        if (!valid) {
          response = Object.assign({}, validation[tab]['matricula_auto_invalida']);
          response["answer"] = response[window.Chat.props.selected_language].replace('##tab##', name_tabs[tab][window.Chat.props.selected_language])
          response["answer"] = response["answer"].replace('##value##', '*' + value + '*')
        }
      }
    }
  }
  return response
}

function validarMatriculaEmbarcacion(value, tab) {
  let response = null
  console.log(value);
  if (value) {
    let valid = patterns["matricula_embarcacion"].test(value)
    if (!valid) {
        response = Object.assign({}, validation[tab]['matricula_embarcacion_invalida']);
        response["answer"] = response[window.Chat.props.selected_language].replace('##tab##', name_tabs[tab][window.Chat.props.selected_language])
        response["answer"] = response["answer"].replace('##value##', '*' + value + '*')
    }
  }
  return response
}

// FUNCTIONS
function isLetter(c) {
  return c.toLowerCase() != c.toUpperCase();
}
