import React from 'react';
import { connect } from 'react-redux';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import { createMuiTheme, MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import { getParams } from './ChatbotQueries';
import Maximized from './Maximized';
import Minimized from './Minimized';
import * as actions from './store/actions';
import validation from './texts/validation.json'
import { getValueFromSessionStored, messagesStored, storeItem } from "./Session";
import { getTimeStamp } from './MyChatList';

const defaultStyles = require('./styles/default.json')
export const defaultTheme = createMuiTheme(defaultStyles)

const styles = (theme) => ({
  paper: {
    position: "fixed !important",
    bottom: "2.5em !important",
    right: "1em !important",
    display: "flex",
    flexDirection: "column",
    maxHeight: "100vh !important",
    transition: "all 100ms ease-out 0s !important",
    transform: "none !important",
    zIndex: "99999",
    "@media (max-width: 490px)": {
      width: "100% !important",
      bottom: "0em !important",
      right: "0em !important",
      height: "100% !important",
    },
    "@media (max-height: 515px)": {
      bottom: "0em !important",
      right: "0em !important",
      height: "100% !important",
    },
    minHeight: 0,
    minWidth: 0,
    height: "500px",
    width: "400px",
    overflowX: "auto",
    overflowY: "hidden"
  },

  close: {
    color: "#fff !important",
    right: "8px !important",
    bottom: "4px !important",
    position: "fixed !important",
    marginRight: "-0.2em !important",
    borderRadius: "0% 50% 50% 50% !important",
    background: "#c8102e !important"
  }
})


class Chat extends React.Component {

  constructor(props) {
    super(props);
    this.getInfoFront();
    this.state = {
      theme: defaultTheme,
      languages: [],
      titleNames: {}
    }
    window.Chat = this;
  }


  async getInfoFront() {
    let global_params = await getParams();
    let materialTheme = createMuiTheme(global_params.style);
    let languages = global_params.languages;
    let titleNames = global_params.params.titleNames;
    this.setState({ theme: materialTheme, languages: languages, titleNames: titleNames });
  };


  updateMessagesOut(messages) {
    this.props.updateMessages(messages);
  }

  maximize() {
    this.props.updateWindowState("maximized");
  }

  minimize() {
    this.props.updateWindowState("minimized");
  }
  //getItemId
  //Lo del idioma
  validationChatbot(name) {
    let m = messagesStored();
    let aux = validation['invocaciones'][name]
    let response = {};
    response["answer"] = aux[this.props.selected_language]
    response["type"] = aux["type"]
    let leng = m.length;
    let message_back = {
      text: response.answer,
      type: response.type,
      data: null,
      isUser: false,
      key: leng++,
      date: getTimeStamp()
    }
    m = m.concat([message_back]);
    this.updateMessagesOut(m);
    storeItem("messages", m);
    if (!window.mobileCheck()) {
      this.maximize()
    }
  }

  componentDidUpdate() {
    if (this.state.windowState === "maximized") {

    }
  }

  render() {
    const { classes } = this.props;
    if (this.props.windowState === "maximized") {
      let height = getValueFromSessionStored("lastWidgetHeight") ? getValueFromSessionStored("lastWidgetHeight") + "px" : "500px";
      let width = getValueFromSessionStored("lastWidgetWidth") ? getValueFromSessionStored("lastWidgetWidth") + "px" : "400px";
      return (
        <MuiThemeProvider theme={this.state.theme}>
          <Grow in={true} style={{ transformOrigin: 'bottom right !important' }}>
            <Paper className={classes.paper} style={{
              height: height, width: width
            }} id="appPaper">
              <Maximized
                languages={this.state.languages}
                params={this.state.params}
                titleNames={this.state.titleNames}
                {...this.props}
              />
            </Paper>
          </Grow>
        </MuiThemeProvider>
      );
    }
    else {
      return (
        <MuiThemeProvider theme={this.state.theme}>
          <Minimized />
        </MuiThemeProvider>
      )
    }
  }
}


const mapStateToProps = state => {
  return {
    selected_language: state.selected_language,
    windowState: state.windowState
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateMessages: (messages) => dispatch(actions.updateMessages(messages)),
    updateWindowState: (windowState) => dispatch(actions.updateWindowState(windowState))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Chat));
