import React from "react";
import { connect } from 'react-redux';
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import texts from "../texts/texts.json"
import * as actions from '../store/actions';

const textos = texts.protecdatos;

const styles = theme => ({
  container: {
    display: "flex !important",
    flexDirection: "column !important",
    paddingTop: "4% !important",
    paddingRight: "4% !important",
    paddingLeft: "4% !important",
    paddingBottom: "4% !important",
    marginBottom: "4px !important"
  },
  titles: {
    fontSize: "0.95em !important",
    fontWeight: "600 !important"
  },
  text: {
    fontSize: "0.9em !important",
    marginTop: "14px !important"
  },
  enlace: {
    fontSize: "0.85em !important",
    marginTop: "20px !important"
  },
  link: {
    // marginLeft: "6px"
  },
  button: {
    marginLeft: "auto !important",
    marginRight: "auto !important",
    marginTop: "30px !important",
    width: "48% !important",
    fontWeight: "600 !important",
    padding: "2px 10px !important",
    textTransform: "none !important",
    fontSize: "0.9em !important",
    "&:hover": {
      backgroundColor: theme.palette.secondary.dark + " !important",
      color: "white !important"
    },
    borderRadius: "2% !important",
    backgroundImage: "none !important",
    textShadow: "none !important"
  }
});

class Protecdatos extends React.Component {

  handleVolver() {
    if (window.getCookie("protecDatosCookie")) {
      this.props.updateScreen("chat");
    } else {
      this.props.updateScreen("initProtecdatos");
    }
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.container} >
        <Typography className={classes.titles} color="inherit" component="p">
          {textos.title[this.props.selected_language]}
        </Typography>
        <Typography className={classes.text} color="inherit" component="p">
          {textos.text[this.props.selected_language]}
        </Typography>
        <Typography className={classes.enlace} color="inherit" component="p">
          {textos.consultar[this.props.selected_language]}<a className={classes.link} target="_blank" href={"http://atv.gva.es/" + this.props.selected_language + "/pd"}>http://atv.gva.es/{this.props.selected_language}/pd</a>
        </Typography>

        <Button
          className={classes.button}
          color="primary"
          variant="contained"
          onClick={this.handleVolver.bind(this)}
        >
          {textos.volver[this.props.selected_language]}
        </Button>
      </div>
    );
  }
}


const mapStateToProps = state => {
  return {
    selected_language: state.selected_language,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateScreen: (screen) => dispatch(actions.updateScreen(screen))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Protecdatos));
