import React from 'react'
import { connect } from 'react-redux';
import Paper from "@material-ui/core/Paper";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';
import ChatIcon from '@material-ui/icons/Chat';
import foto from "./images/chat_rojo.png"
import texts from "./texts/texts.json"
import * as actions from './store/actions';
import { Fragment } from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const textos = texts.minimized;
const PRINCIPAL_PATHNAMES = ["pre-atv.gva.es", "atv.gva.es"];

const styles = theme => ({
  minimized: {
    display: "flex !important",
    alignItems: "center !important",
    justifyContent: "center !important",
    cursor: "pointer !important",
    position: "fixed !important",
    right: "1em !important",
    bottom: "2em !important",
    animationName: "fadeInOut, moveLeft300px, bounce",
    animationDuration: "6s",
    animationTimingFunction: "cubic-bezier(0.6, 0.5, 0.3, 0.1)",
    animationIterationCount: "infinite",
    zIndex: 99999
  },
  paper: {
    backgroundColor: "white !important",
    width: "240px !important",
    borderRadius: "10px !important",
    fontFamily: "'open_sans',Helvetica,Arial,sans-serif !important",
    paddingLeft: "1em !important",
    paddingRight: "0.6em !important",
    marginRight: "0.4em !important",
    fontWeight: "600 !important",
    "&:hover": { backgroundColor: "#F2F2F2 !important" }
  },
  labelTitle: {
    fontSize: "18px !important",
    fontWeight: "600 !important",
    color: theme.palette.primary.main + " !important",
    marginBottom: "auto",
    marginTop: "auto"
  },
  labelLabel: {
    fontSize: "14px !important",
    fontWeight: "600 !important",
    color: "#333333 !important",
    width: "80% !important",
    marginTop: "0px !important"
  },
  chatIcon: {
    position: "absolute !important",
    width: "59px !important",
    height: "59px !important",
    right: "12px !important",
    bottom: "-15px !important",
    maxWidth: "inherit !important"
  },
  badge: {
    right: "20px !important",
    bottom: "57px !important",
  },
  msg: {
    fontSize: "2.1em !important"
  },
  flexDiv: {
    display: "flex !important",
    marginTop: "2px !important",
    marginRight: "2px !important",
    marginLeft: "-4px !important",
    paddingBottom: "0.6em !important",
    paddingTop: "0.4em !important"
  },
  closeIcon: {
    height: "22px !important",
    width: "22px !important",
    marginBottom: "auto !important",
    marginTop: "auto !important",
    marginRight: "10px !important",
    background: "none !important"
  }
})

class Minimized extends React.Component {

  constructor(props) {
    super(props);
    window.Minimized = this;
    this.state = {
      open: true,
      counter: 0
    }
  }

  maximize = () => {
    this.props.updateWindowState("maximized");
  }

  increment() {
    this.setState(prevState => {
      return {
        ...prevState,
        counter: prevState.counter + 1
      }
    })
  }

  clickClose() {
    this.setState({ open: false });
  }

  getLabel() {
    return ((PRINCIPAL_PATHNAMES.includes(window.location.hostname) || window.location.href.split('?')[0] === "http://localhost:3000/" )? textos.label_portal[this.props.selected_language]:textos.label_620[this.props.selected_language] );
  }


  render() {
    const { classes } = this.props;
    return (
      <div
        className={classes.minimized}
        // onClick={this.maximize}
      >
        {this.state.open ?
          (<Fragment>
            <Paper className={classes.paper}>
              <div className={classes.flexDiv} >
                <IconButton className={classes.closeIcon} onClick={this.clickClose.bind(this)} >
                  <CloseIcon  />
                </IconButton>
                <p className={classes.labelTitle} onClick={this.maximize}>
                  {textos.title[this.props.selected_language]}
                </p>
              </div>
              <div>
                <p className={classes.labelLabel} onClick={this.maximize}>
                  {this.getLabel()}
                </p>
              </div>
            </Paper>
            <Badge className={classes.badge} onClick={this.maximize} badgeContent={this.state.counter} color="primary">
              <ChatIcon className={classes.msg} />
            </Badge>
          </Fragment>) : null}
        <img onClick={this.maximize} src={foto} className={classes.chatIcon} alt="chatIcon" />
      </div>
    )
  }
}


Minimized.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {
    selected_language: state.selected_language,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateWindowState: (windowState) => dispatch(actions.updateWindowState(windowState))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Minimized));
