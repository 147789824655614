import React from "react";
import * as actions from '../store/actions';
import { connect } from 'react-redux';
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Paper } from "@material-ui/core";
import texts from "../texts/texts.json"
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';

const textos = texts.valorarCard;

const styles = theme => ({
  messageBubble: {
    flexDirection: "row !important",
    overflow: "hidden !important",
    alignItems: "flex-end !important",
    margin: "0.3em !important",
    marginRight: "1em !important",
    width: "280px !important",
    borderRadius: "1em !important",
  },
  divUp: {
    display: "flex !important",
    padding: "1em !important"
  },
  icon: {
    fontSize: "3em !important",
    marginTop: "auto !important",
    marginBottom: "auto !important"
  },
  title: {
    paddingLeft: "1em !important",
    fontSize: "0.95em !important",
    color: "#333333 !important",
    fontWeight: "600 !important",
    marginTop: "auto !important",
    marginBottom: "auto !important"
  },
  body: {
    paddingLeft: "1em !important",
    paddingRight: "1em !important",
    fontSize: "0.85em !important",
    color: "#333333 !important"
  },
  button: {
    padding: "0.3em !important",
    marginTop: "0.8em !important",
    width: "100% !important",
    position: "relative !important",
    textTransform: "none !important",
    fontSize: "0.9em !important",
    fontWeight: "600 !important",
    borderBottom: "none !important",
    borderRight: "none !important",
    borderLeft: "none !important",
    borderRadius: "2% !important",
    borderColor: "#ececec !important",
    "&:hover": {
      borderBottom: "none !important",
      borderRight: "none !important",
      borderLeft: "none !important",
      borderRadius: "0em !important",
      borderColor: "#ececec !important",
      color: "white !important"
    },
    backgroundImage: "none !important",
    textShadow: "none !important"
  }
});

class ValorarCard extends React.Component {

  render() {
    const { classes } = this.props;
    return (
      <Paper
        className={classes.messageBubble}
        style={{
          backgroundColor: "white !important",
          color: "#263238 !important",
          border: "1px solid #dee1e5 !important"
        }}
      >
        <div className={classes.divUp}>
          <SentimentVerySatisfiedIcon className={classes.icon} color="secondary"/>
          <Typography className={classes.title} color="inherit" component="p">
            {textos.title[this.props.selected_language]}
          </Typography>
        </div>

        <Typography className={classes.body} color="inherit" component="p">
          {textos.body[this.props.selected_language]}
        </Typography>

        <Button className={classes.button} onClick={() => this.props.updateScreen("valoracion")} color="primary" variant="outlined">
          Valorar
        </Button>
      </Paper>
    );
  }
}


const mapStateToProps = state => {
  return {
    selected_language: state.selected_language
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateScreen: (screen) => dispatch(actions.updateScreen(screen))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ValorarCard));
