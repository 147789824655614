import React from "react";
import { connect } from 'react-redux';
import { messageCall, languageCall, getLanguageUser, eventsCall,  getUserCall} from "./ChatbotQueries";
import MyTextInput from "./components/MyTextInput";
import MyList from "./MyList";
import { storeItem, messagesStored } from "./Session";
import * as actions from './store/actions';
import Valoracion from './components-atv/Valoracion';
import Protecdatos from './components-atv/Protecdatos';
import InitProtecdatos from "./components-atv/InitProtecdatos";

const BOT_TIME = process.env.REACT_APP_BOT_TIME;
const PRINCIPAL_PATHNAMES = ["pre-atv.gva.es", "atv.gva.es"];

function getTimeStamp() {
  var d = new Date();
  var h = d.getHours().toString();
  var m = d.getMinutes().toString();

  if (m < 10) return h + ":0" + m;
  else return h + ":" + m;
}


async function get_user() {
  let response = await getUserCall();
  console.log(response["user_id"]);
  return response["user_id"];
}

function timeout(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

class MyChatList extends React.Component {

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.key = this.props.messages.length;
    window.MyChatList = this;
    this.handleStart();
  }


  getInitialState() {
    return {
      text: "",
      disableInput: false,
      renderInput: true,
      user: ""
    };
  }

  async handleStart() {
    let intentos_user = 0;
    let user = null
    while (!user && intentos_user < 3) {
      user = await get_user()
      intentos_user++;
    }
    this.setState({user: user});
    await this.urlCall(this.state.user)
    await languageCall(this.state.user, this.props.selected_language);
    let intentos = 0;
    while (this.props.selected_language !== await getLanguageUser(this.state.user) && intentos < 3) {
      await languageCall(this.state.user, this.props.selected_language);
      intentos++;
    }

    // console.log("numero de mensajes:"+messagesStored().length)
    // if(window.getCookie("protecDatosCookie")){
    //   console.log("Haentrado en el component did update")
    //   console.log("Hay cookie")
    // }else{
    //   console.log("No ha encontrado la cookie")
    // }
    if (messagesStored().length === 0 && window.getCookie("protecDatosCookie")) {
      //console.log("Va a enviar el welcome desde handle start de mychat List")
      const event_id = (PRINCIPAL_PATHNAMES.includes(window.location.hostname) || window.location.href === "http://localhost:3000/") ? "welcome_message" : "welcome_message_620";
      let data = {};
      if (event_id === "welcome_message") {
        let env = "pre";
        if (window.location.hostname === "localhost") {
          if (window.location.port === "3000") {
            env = "pro";
          }
        } else if (window.location.hostname === "atv.gva.es" || window.location.hostname === "atenea.ha.gva.es") {
          env = "pro";
        }
        data = { env: env };
      }
      let response = await eventsCall(
        {
          event_id: event_id,
          user_id: this.state.user,
          data: data
        },
        this.props.selected_language
      );
      this.handleResponse(response);
    }
  }


  handleOnSendMessage(msg) {
    this.setState({
      text: ""
    });
    let message = {
      text: msg,
      type: "text",
      isUser: true,
      key: this.key++,
      date: getTimeStamp(),
    };
    this.sendMessage(message);
    this.chatbotBackendQuery(message.text, this.props.selected_language);
  }

  handleOnSendPostbackMessage(msg, postback) {
    this.setState({
      text: ""
    });
    let message = {
      text: msg,
      type: "text",
      isUser: true,
      key: this.key++,
      date: getTimeStamp(),
    };
    this.sendMessage(message);
    this.chatbotBackendQuery(postback, this.props.selected_language);
  }


  async handleOnSendEventMessage(msg) {
    this.setState({
      text: ""
    });
    let message = {
      text: msg.text,
      type: "text",
      isUser: true,
      key: this.key++,
      date: getTimeStamp(),
    };
    this.sendMessage(message);
    let response = await eventsCall(
      { event_id: "get_answer", user_id: this.state.user, data: { "payload": msg.payload } },
      this.props.selected_language
    );
    this.handleResponse(response);
  }


  async chatbotBackendQuery(message, language) {
    let response = await messageCall(message, this.state.user, language);
    this.handleResponse(response);
  }


  async languageBackendQuery(language) {
    if (language) {
      await languageCall(this.state.user, language);
      let intentos = 0;
      while (this.props.selected_language !== await getLanguageUser(this.state.user) && intentos < 3) {
        await languageCall(this.state.user, this.props.selected_language);
        intentos++;
      }
      await eventsCall(
        { event_id: "change_language", user_id: this.state.user },
        language
      );
    }
  }


  async handleResponse(response) {
    let send_message = true;

    let answers = [];
    for (var msg = 0; msg < response.length; msg++) {
      let message_back = {
        text: response[msg].answer,
        type: response[msg].type,
        data: response[msg].data,
        isUser: false,
        key: this.key++,
        date: getTimeStamp(),
      };
      answers.push(message_back)
    }
    let m = this.props.messages;
    m = m.concat(answers);
    storeItem("messages", m);

    for (var msg = 0; msg < response.length; msg++) {
      let message_back = {
        text: response[msg].answer,
        type: response[msg].type,
        data: response[msg].data,
        isUser: false,
        key: this.key++,
        date: getTimeStamp(),
      };

      let time;
      let words_number;

      if (BOT_TIME) {
        time = BOT_TIME;
      } else if (msg === 0) {
        time = 300;
      } else {
        words_number = response[msg - 1].answer.split(" ")
        time = words_number.length * 65;
      }

      if (message_back.type === "welcome_main" || message_back.type === "welcome_620") {
        if (BOT_TIME) {
          time = BOT_TIME;
        } else if (msg === 0) {
          time = 300;
        } else {
          words_number = response[msg - 1].answer.split(" ")
          time = words_number.length * 65;
        }
        if (message_back.type === "welcome_main") {
          this.setState({
            renderInput: true
          });
        }
      } else if (message_back.type === "change_language") {
        send_message = false;
        if (this.props.selected_language === "va") {
          this.props.updateLanguage("es");
        } else {
          this.props.updateLanguage("va");
        }
      } else if (message_back.type === "redirect_link") {
        window.open(message_back.data[0].link,'_blank');
      } else if (message_back.type === "text") {
        this.setState({
          renderInput: true
        });
      }
      if (send_message) {
        this.props.is_writing(true);
        await timeout(time);
        this.sendMessage(message_back);
        this.props.is_writing(false);
      }
    }
  }


  sendMessage(message) {
    let m = this.props.messages;
    m = m.concat([message]);
    this.props.updateMessages(m);
    // storeItem("messages", m);
    this.props.is_writing(false);
  }


  async submitForm(form) {
    this.setState({ text: "" });
    form.user_id = this.state.user;
    let response = await eventsCall(form, this.props.selected_language);
    this.handleResponse(response);
  }


  onChange = (value) => {
    this.setState({ text: value });
  };


  shouldComponentUpdate(nextProps) {
    if (
      nextProps.selected_language !== this.props.selected_language &&
      this.props.selected_language !== null
    ) {
      this.languageBackendQuery(nextProps.selected_language);
    }
    return true;
  }


  renderInput() {
    if (this.state.renderInput) {
      return (
        <MyTextInput
          style={{
            height: "auto !important",
            display: "block !important",
            position: "absolute !important",
            bottom: "1em !important",
          }}
          writing={this.props.writing}
          disable={this.state.disableInput}
          onSend={this.handleOnSendMessage.bind(this)}
          text={this.state.text}
          onChange={this.onChange}
        />);
    } else { return null; }
  }


  renderScreen() {
    switch (this.props.screen) {
      case "chat":
        return (
          <div
            style={{
              height: "100%",
              display: "flex",
              flexFlow: "column",
              minHeight: "0",
            }}
          >
            <MyList
              onButtonClick={this.handleOnSendMessage.bind(this)}
              onPostbackButtonClick={this.handleOnSendPostbackMessage.bind(this)}
              eventButtonClick={(m) => this.handleOnSendEventMessage(m)}
              handleResponse={this.handleResponse.bind(this)}
              user={this.state.user}
            />
            {this.renderInput()}
          </div>);

      case "initProtecdatos":
        console.log(`Caso initProtectdatos`)

        return (
          <div
            style={{
              height: "100%",
              display: "flex",
              flexFlow: "column",
              minHeight: "0",
            }}
          >
            <InitProtecdatos
              user={this.state.user}
              handleResponse={(response) => this.handleResponse(response)}
            />
          </div>);

      case "valoracion":
        return (
          <div
            style={{
              height: "100%",
              display: "flex",
              flexFlow: "column",
              minHeight: "0",
            }}
          >
            <Valoracion submitForm={this.submitForm.bind(this)} />
          </div>);

      case "protecdatos":
        return (
          <div
            style={{
              height: "100%",
              display: "flex",
              flexFlow: "column",
              minHeight: "0",
            }}
          >
            <Protecdatos />
          </div>);

      default:
        return null;
    }
  }

  getUrl() {
     if (window.location.hostname === "localhost") {
       return window.location.pathname === "/" ? "portal" : "620";
     } else {
       return window.location.hostname === "atv.gva.es"|| window.location.hostname === "pre-atv.gva.es" ? "portal" : "620"
     }
   }

  async urlCall(user){
    let data={
      url:this.getUrl()
    }
    await eventsCall(
      {
        event_id: "cambio_url",
        user_id: user,
        data: data
      },
      this.props.selected_language
    );
  }

  async componentDidUpdate(nextProps) {
    //console.log("Ha entrado en el component did update")
    if (
      nextProps.selected_language !== null &&
      nextProps.selected_language !== this.props.selected_language
    ) {
      this.setState({
        text: "",
        disableInput: false,
      });
      this.props.updateMessages([]);
      if (window.getCookie("protecDatosCookie")) {

        const event_id = (PRINCIPAL_PATHNAMES.includes(window.location.hostname) || window.location.href === "http://localhost:3000/") ? "welcome_message" : "welcome_message_620";
        let data = {};
        if (event_id === "welcome_message") {
          let env = "pre";
          if (window.location.hostname === "localhost") {
            if (window.location.port === "3000") {
              env = "pro";
            }
          } else if (window.location.hostname === "atv.gva.es" || window.location.hostname === "atenea.ha.gva.es") {
            env = "pro";
          }
          data = { env: env };
        }
        let intentos = 0;
        while (this.props.selected_language !== await getLanguageUser(this.state.user) && intentos < 3) {
          await languageCall(this.state.user, this.props.selected_language);
          intentos++;
        }
        //console.log("Va a enviar el welcome desde componentDinUpdate de mychat List")
        let response = await eventsCall(
          {
            event_id: event_id,
            user_id: this.state.user,
            data: data
          },
          this.props.selected_language
        );
        this.handleResponse(response);
      }
    }
  }


  render() {
    return (
      this.renderScreen()
    );
  }
}


const mapStateToProps = state => {
  return {
    selected_language: state.selected_language,
    screen: state.screen,
    messages: state.messages
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateLanguage: (language) => dispatch(actions.updateLanguage(language)),
    updateMessages: (messages) => dispatch(actions.updateMessages(messages)),
    updateWindowState: (windowState) => dispatch(actions.updateWindowState(windowState))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyChatList);
export { getTimeStamp };
