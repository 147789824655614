export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';
export const CHANGE_SCREEN = 'CHANGE_SCREEN';
export const CHANGE_MESSAGES = 'CHANGE_MESSAGES';
export const CHANGE_WINDOWSTATE = 'CHANGE_WINDOWSTATE';


const changeLanguage = (language) => {
    return {
        type: CHANGE_LANGUAGE,
        selected_language: language
    };
};
export const updateLanguage = (language) => {
    return async dispatch => {
        dispatch(changeLanguage(language));
    }
};


const changeScreen = (screen) => {
    return {
        type: CHANGE_SCREEN,
        screen: screen
    };
};
export const updateScreen = (screen) => {
    return async dispatch => {
        dispatch(changeScreen(screen));
    }
};


const changeMessages = (messages) => {
    return {
        type: CHANGE_MESSAGES,
        messages: messages
    };
};
export const updateMessages = (messages) => {
    return async dispatch => {
        dispatch(changeMessages(messages));
    }
};


const changeWindowstate = (windowState) => {
    return {
        type: CHANGE_WINDOWSTATE,
        windowState: windowState
    };
};
export const updateWindowState = (windowState) => {
    return async dispatch => {
        dispatch(changeWindowstate(windowState));
    }
};
