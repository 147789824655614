import React from 'react';
import { connect } from 'react-redux';
import AppBar from '@material-ui/core/AppBar';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import locale from '../texts/locale.json';
import IconButton from '@material-ui/core/IconButton';
import RemoveIcon from '@material-ui/icons/Remove';
import * as actions from '../store/actions';
import avatar from "../images/avatar.png"
import resize from "../images/resize.png"
import { getValueFromSessionStored } from '../Session';


const styles = theme => ({
  toolbarRoot: {
    display: "flex !important",
    minHeight: "55px !important",
    backgroundColor: theme.palette.primary.main + " !important",
    paddingRight: "18px !important",
    paddingLeft: "18px !important"
  },
  title: {
    fontSize: "1.26em !important",
    color: theme.palette.primary.light + " !important"
  },
  avatar: {
    width: "58px !important",
    height: "22px !important"
  },
  header: {
    marginLeft: '0.9em !important',
    backgroundColor: 'inherit !important',
    border: 'none !important',
    flex: "1 !important"
  },
  chatState: {
    fontStyle: 'inherit !important',
    fontSize: '12px !important',
    color: theme.palette.primary.light + " !important"
  },
  fabLanguage: {
    minWidth: "30px !important",
    minHeight: "30px !important",
    width: "30px !important",
    height: "30px !important",
    color: theme.palette.primary.main + " !important",
    fontSize: "0.76em !important",
    textTransform: "capitalize !important",
    borderRadius: "50% !important",
    backgroundColor: theme.palette.primary.light + " !important",
    "&:active": { boxShadow: "none !important" },
    boxShadow: "none !important",
    marginRight: "0.4em !important"
  },
  close: {
    width: "54px !important",
    height: "54px !important",
    padding: "9px !important",
    color: theme.palette.primary.light + " !important",
    borderRadius: "50% !important",
    backgroundImage: "none !important"
  },
  resize: {
    marginRight: "0.75em !important",
    width: "54px !important",
    height: "54px !important",
    padding: "9px !important",
    color: theme.palette.primary.light + " !important",
    borderRadius: "50% !important",
    backgroundImage: "none !important",
    background: "none"
  },
  icon: {
    fontSize: "1.5em !important"
  },

});


class ChatToolbar extends React.Component {

  getState() {
    if (this.props.is_writing) {
      if (this.props.selected_language) {
        return locale[this.props.selected_language].writing
      }
    } else {
      if (this.props.selected_language) {
        return locale[this.props.selected_language].online
      }
    }
  }


  minimize = () => {
    this.props.updateWindowState("minimized");
  }

  componentDidMount() {
    const initHeight = getValueFromSessionStored("lastWidgetHeight") || 500;
    const initWidth = getValueFromSessionStored("lastWidgetWidth") || 400;
    var
      ht = initHeight,
      wd = initWidth,
      main = document.getElementById("appPaper"),
      x, y, dx, dy;

    var startResize = function (evt) {
      x = evt.screenX;
      y = evt.screenY;
    };

    var resize = function (evt) {
      dx = evt.screenX - x;
      dy = evt.screenY - y;
      x = evt.screenX;
      y = evt.screenY;
      wd -= dx;
      ht -= dy;
      main.style.width = wd + "px";
      main.style.height = ht + "px";
    };

    document.getElementById("resizeButton").addEventListener("mousedown", function (evt) {
      startResize(evt);
      document.body.addEventListener("mousemove", resize);
      document.body.addEventListener("mouseup", function () {
        document.body.removeEventListener("mousemove", resize);
      });
    });
  }


  changeLanguage() {
    if (this.props.selected_language === "va") {
      this.props.updateLanguage("es");
    } else {
      this.props.updateLanguage("va");
    }
  }


  render() {
    const { classes } = this.props;

    return (
      <AppBar position="static">
        <Toolbar classes={{ root: classes.toolbarRoot }}>
          <IconButton className={classes.resize} style={{background:"none"}} key="resize" aria-label='Redimensionar Asistente Virtual' id="resizeButton">
            <img src={resize} style={{ width: "16px", 'pointer-events': "none", filter: "invert(100%)" }} class="invert" />
          </IconButton>
          <img className={classes.avatar} src={avatar} alt='Agencia Tributaria Valenciana' />
          <Paper className={classes.header} elevation={0}>
            <Typography className={classes.title} color="inherit" component="p">
              {this.props.titleNames[this.props.selected_language]}
            </Typography>
            <Typography component="p" className={classes.chatState}>
              {this.getState()}
            </Typography>
          </Paper>
          <IconButton className={classes.close} key="close" aria-label='Cerrar Asistente Virtual' onClick={this.minimize}>
            <RemoveIcon className={classes.icon} />
          </IconButton>
        </Toolbar>
      </AppBar>
    )
  }
}


ChatToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
};


const mapStateToProps = state => {
  return {
    selected_language: state.selected_language,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateWindowState: (windowState) => dispatch(actions.updateWindowState(windowState))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ChatToolbar));
