import React from "react";
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import texts from "../texts/texts.json"
import * as actions from '../store/actions';
import { eventsCall } from "../ChatbotQueries";
import { storeItem } from "../Session";
import { getTimeStamp } from '../MyChatList';

const textos = texts.change620Button;

const styles = theme => ({
  button: {
    width: "40% !important",
    fontSize: "0.9em !important",
    fontWeight: "600  !important",
    marginLeft: "auto !important",
    marginRight: "auto !important",
    borderRadius: "2% !important",
    textTransform: "none !important"
  }
});


class Change620Button extends React.PureComponent {

  async clickDeAcuerdo() {
    this.props.updateWindowState("minimized");
    
    this.props.updateMessages([]);
    let response = await eventsCall(
      { event_id: "welcome_message_620", user_id: this.props.user },
      this.props.selected_language
    );
   
    let m = [];
    let leng = response.length;
    for (var msg = 0; msg < response.length; msg++) {
      let message_back = {
        text: response[msg].answer,
        type: response[msg].type,
        data: response[msg].data,
        isUser: false,
        key: leng++,
        date: getTimeStamp(),
      };
      m = m.concat([message_back]);
      this.props.updateMessages(m);
      storeItem("messages", m);
    }

    let env = "pre";
    if (window.location.hostname === "localhost") {
      if (window.location.port === "3000") {
        env = "local";
      }
    } else if (window.location.hostname === "atv.gva.es" || window.location.hostname === "atenea.ha.gva.es") {
        env = "pro";
    }
    window.location.replace(JSON.parse(process.env.REACT_APP_URL_620)[env][this.props.selected_language], "_blank")
  }

  render() {
    const { classes } = this.props;
    return (
      <Button
        className={classes.button}
        color="primary"
        variant="contained"
        onClick={this.clickDeAcuerdo.bind(this)}
      >
        {textos.deAcuerdo[this.props.selected_language]}
      </Button>
    );
  }
}

Change620Button.propTypes = {
  classes: PropTypes.object.isRequired
};


const mapStateToProps = state => {
  return {
    selected_language: state.selected_language,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateScreen: (screen) => dispatch(actions.updateScreen(screen)),
    updateMessages: (messages) => dispatch(actions.updateMessages(messages)),
    updateWindowState: (windowState) => dispatch(actions.updateWindowState(windowState))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Change620Button));
