import axios from 'axios';
import locale from "./texts/locale.json";
import { defaultTheme } from "./App";

const restUrlLocal = process.env.REACT_APP_REST_HOST_LOCAL;
const restUrlPRE = process.env.REACT_APP_REST_HOST_PRE;
const restUrlPRO = process.env.REACT_APP_REST_HOST_PRO;
const chatbot = process.env.REACT_APP_CHATBOT;
const isLocal = process.env.REACT_APP_IS_LOCAL;

// const options = { withCredentials: true }
let headers = { headers: { "Ocp-Apim-Subscription-Key": "e8152e1236c94eabaa4fe52c584d35da" } };
if (isLocal) {
  headers = { headers: { "Authorization": "b4b3ea90-db9a-4497-abe8-4e64abbaf0887934kls" } };
}

let restUrl = "";
if (window.location.hostname === "localhost") {
  if (window.location.port === "3000") {
    restUrl = restUrlLocal;
  } else {
    restUrl = restUrlPRE;
  }
} else if (window.location.hostname === "sara-2-620.gva.es" || window.location.hostname === "atv.gva.es") {
  restUrl = restUrlPRO;
  headers = { headers: { "Ocp-Apim-Subscription-Key": "5a1b4e69316d442797506d986f2f0d34" } };
} else {
  restUrl = restUrlPRE;
}


function processMessage(msg) {
  const charsToReplace = [/'/g, /`/g, /"/g];
  charsToReplace.forEach(regex => {
    msg = msg.replace(regex, '')
  })
  return msg;
}

export async function getUserCall() {
  let response = {};
  if (isLocal) {
    response = await backendGet(restUrl + "/user")
  } else {
    response = await backendGet(restUrl, "user")
  }
  response = response.response
  if (!response){
    response={user_id:null}
  }
  return response;
 }


export async function messageCall(message, id, language) {
  const data = {
    msg: processMessage(message),
    user_id: id,
    channel: 'WEB',
    arguments: {},
    url: window.location.href.replace("https://","")
  }
  if (isLocal) {
    let { response } = await backendPost(restUrl + "/ask_multiskill", {}, data, language)
    return response;
  } else {
    let { response } = await backendPost(restUrl, "ask_multiskill", data, language)
    return response;
  }
}


export async function languageCall(id, language) {
  const data = {
    language: language,
    user_id: id,
    channel: 'WEB'
  }
  if (isLocal) {
    let { response } = await backendPost(restUrl + "/user/language", {}, data, language)
    return response;
  } else {
    let { response } = await backendPost(restUrl, "user/language", data, language)
    return response;
  }
}


export async function eventsCall(data, language) {
  let body = {
    event_id: data.event_id,
    user_id: data.user_id,
    channel: "WEB"
  }
  if (data.data) {
    body["arguments"] = data.data
  }
  if (isLocal) {
    let { response } = await backendPost(restUrl + "/event", {}, body, language)
    return response;
  } else {
    let { response } = await backendPost(restUrl, "event", body, language)
    return response;
  }
}


export async function getParams() {
  let response = {}
  if (isLocal) {
    response= await backendGet(restUrl + "/global")
  } else {
    response = await backendGet(restUrl, "global")
  }
  response = response.response
  if(!response){
    response = {
      style: defaultTheme,
      languages: ["es"],
    };
  }
  return response;

}


export async function getLanguageUser(user) {
  let response;
  if (isLocal) {
    let url = restUrl + "/user/language?user_id=" + user
    response = await axios.get(url, headers);
  } else {
    response = await backendGet(restUrl, "user/language", { "user_id": user })
  }
  if (isLocal) {
    if (Object.keys(response.data).length === 0) {
      return null;
    }
    return response.data.language;
  } else {
    if (Object.keys(response.response).length === 0) {
      return null;
    }
    return response.response.language;
  }
}


async function backendPost(url, method, data, language) {
  let response;
  let status;

  if (!isLocal) {
    data['bot'] = chatbot;
    data['method'] = method;
  }

  try {
    response = await axios.post(url, data, headers);
    status = response.status;
    response = response.data;

  }
  catch (error) {
    status = 500
    response = null
  }


  if (status === 500 && !response) {
    response = [{
      answer: locale[language].error500,
      type: "text",
      data: {}
    }];
  }
  else if (status === 400) {
    response = [{
      answer: locale[language].error400,
      type: "text",
      data: {}
    }];
  }
  return { response, status };
}


async function backendGet(url, method, params = {}) {
  let response;
  let status;

  let argumentos = {};
  if (!isLocal) {
    params["method"] = method;
    params["bot"] = chatbot;
    argumentos.params = params;
    argumentos.headers = headers["headers"]
  }

  try {
    response = await axios.get(url, argumentos);
    status = response.status;
    response = response.data;
    return { response, status };
  }
  catch (error) {
    response = error.response
    console.log(error)
    return {"response": null};
  }
}