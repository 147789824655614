import React from 'react';
import ChatToolbar from './components/ChatToolbar';
import MyChatList from './MyChatList';

class Maximized extends React.Component {

  state = {
    is_writing: false
  }

  switchState(state) {
    this.setState({
      is_writing: state
    });
  }

  render() {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%'
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column', height: "100%" }}>
          <ChatToolbar
            is_writing={this.state.is_writing}
            languages={this.props.languages}
            titleNames={this.props.titleNames}
          />
          <MyChatList
            is_writing={this.switchState.bind(this)}
            writing={this.state.is_writing}
            params={this.props.params}
          />
        </div>
      </div>
    )
  }
}

export default Maximized;
