import React from "react";
import { connect } from 'react-redux';
import ListSubheader from "@material-ui/core/ListSubheader";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMore from "@material-ui/icons/ExpandMore";
import texts from "../texts/texts.json"

const textos = texts.desplegableInicio;

const styles = theme => ({
  root: {
    width: "100% !important",
    maxWidth: "360 !important"
  },
  nested: {
    paddingLeft: "10% !important"
  },
  text: {
    fontSize: "0.85em !important",
    marginLeft: "15px !important",
    color: theme.palette.primary.main + " !important",
    lineHeight: "15px !important",
    fontWeight: "600 !important",
    marginTop: "5px !important",
    marginBottom: "5px !important"
  },
  text2: {
    color: "rgba(0, 0, 0, 0.54) !important",
    fontSize: "0.85em !important",
  },
  textDesplegable: {
    fontSize: "0.85em !important",
    color: "#333333 !important",
    lineHeight: "15px !important",
    fontWeight: "600  !important"
  },
  divDesplegable: {
    padding: "0 !important",
    paddingBottom: "4px !important",
    paddingTop: "6px !important"
  },
  item: {
    padding: "3 !important"
  },
  headers: {
    fontSize: "0.85em !important",
  },
  listSubheader: {
    fontSize: "0.95em !important",
    fontWeight: "600 !important",
    color: "#333333 !important"
  },
  divList: {
    borderStyle: "solid !important",
    borderWidth: "1px !important",
    borderRight: "none !important",
    borderLeft: "none !important",
    borderColor: "rgb(222, 225, 229) !important"
  }
});

class DesplegableInicio extends React.Component {
  state = {
    faqs: false,
    open: false
  };

  handleClick = menu => {
    this.setState({ [menu]: !this.state[menu] });
    this.setState(prevState => ({ open: !prevState.open }));
  };

  buttonClick = event => {
    this.props.onButtonClick(event);
  };

  render() {
    const { classes } = this.props;
    const { data } = this.props;
    const divProps = Object.assign({}, this.props);
    delete divProps.onButtonClick;
    let title = textos.temasSugeridos[this.props.selected_language];
    return (
      <Card
        style={{
          boxShadow: "none !important",
          borderRadius: "0em 1em 1em !important",
          border: "1px solid rgb(222, 225, 229) !important",
          marginRight: "auto !important",
          width: "276px !important"
        }}
      >
        <List
          style={{ paddingBottom: "0px !important" }}
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader
              className={classes.listSubheader}
              component="div"
              id="nested-list-subheader"
            >
              {title}
            </ListSubheader>
          }
          className={classes.root}
        >
          {data.map(t => {
            if (t.type === "boton") {
              return (
                <div
                  style={{
                    borderStyle: "solid !important",
                    borderWidth: "1px !important",
                    borderRight: "none !important",
                    borderLeft: "none !important",
                    borderColor: "rgb(222, 225, 229) !important",
                    borderBottom: "none !important"
                  }}
                  key={data.indexOf(t)}
                >
                  <ListItem
                    className={classes.item}
                    button
                    onClick={this.buttonClick}
                  >
                    <ListItemText
                      disableTypography
                      primary={
                        <Typography className={classes.text}>
                          {t.text_label}
                        </Typography>
                      }
                    />
                  </ListItem>
                </div>
              );
            } else {
              return (
                <div
                  style={{
                    borderStyle: "solid !important",
                    borderWidth: "1px !important",
                    borderRight: "none !important",
                    borderLeft: "none !important",
                    borderColor: "rgb(222, 225, 229) !important",
                    borderBottom: "none !important"
                  }}
                  key={data.indexOf(t)}
                >
                  <ListItem
                    className={classes.item}
                    button
                    onClick={() => {
                      this.handleClick(t.data);
                    }}
                  >
                    <ListItemText
                      disableTypography
                      primary={
                        <Typography className={classes.text}>
                          {t.label}
                        </Typography>
                      }
                    />
                    {this.state.open ? (
                      <ExpandLessIcon
                        color="primary"
                        style={{ marginRight: "0.6em !important" }}
                      />
                    ) : (
                        <ExpandMore
                          color="primary"
                          style={{ marginRight: "0.6em !important" }}
                        />
                      )}
                  </ListItem>
                  {t.text_label.map(m => (
                    <div key={t.text_label.indexOf(m)}>
                      <Collapse
                        in={this.state[t.data]}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List component="div" disablePadding>
                          <ListItem
                            style={{
                              backgroundColor: "#f0f0f0 !important",
                              borderStyle: "solid none !important",
                              borderWidth: "1px medium !important",
                              borderColor: "rgb(222, 225, 229) !important",
                              borderBottom: "none !important"
                            }}
                            button
                            className={classes.nested}
                            onClick={this.buttonClick}
                          >
                            <ListItemText
                              disableTypography
                              className={classes.divDesplegable}
                              primary={
                                <Typography className={classes.textDesplegable}>
                                  {m.text}
                                </Typography>
                              }
                            />
                          </ListItem>
                        </List>
                      </Collapse>
                    </div>
                  ))}
                </div>
              );
            }
          })}
        </List>
      </Card>
    );
  }
}


const mapStateToProps = state => {
  return {
    selected_language: state.selected_language,
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DesplegableInicio));
