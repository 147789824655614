import React from "react";
import { connect } from 'react-redux';
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import StarRatings from "react-star-ratings";
import texts from "../texts/texts.json"
import * as actions from '../store/actions';

const textos = texts.valoracion;

const styles = theme => ({
  container: {
    display: "flex !important",
    flexDirection: "column !important",
    paddingTop: "4% !important",
    paddingRight: "4% !important",
    paddingLeft: "4% !important",
    paddingBottom: "4% !important",
    marginBottom: "4px !important"
  },
  titles: {
    fontSize: "14px !important",
  },
  opinion: {
    fontSize: "14px !important"
  },
  divRating: {
    marginLeft: "auto !important",
    marginRight: "auto !important",
    paddingTop: "0.8em !important"
  },
  gensMolt: {
    display: "flex !important",
    marginBottom: "0.8em !important"
  },
  gens: {
    marginLeft: "20% !important",
    color: "#9B9B9B !important",
    fontSize: "0.9em !important"
  },
  molt: {
    marginRight: "21% !important",
    color: "#9B9B9B !important",
    fontSize: "0.9em !important"
  },
  textField: {
    marginTop: "12px !important"
  },
  input: {
    fontSize: "13px !important",
    height: "10em !important",
    boxShadow: "none !important",
    "&:focus": {
      border: "none !important",
      background: "none !important"
    }
  },
  divDown: {
    display: "flex !important",
    marginTop: "0.8em !important"
  },
  button: {
    width: "48% !important",
    fontWeight: "600 !important",
    padding: "2px 10px !important",
    textTransform: "none !important",
    fontSize: "0.9em !important",
    "&:hover": {
      backgroundColor: theme.palette.secondary.dark + " !important",
      color: "white !important"
    },
    borderRadius: "2% !important",
    backgroundImage: "none !important",
    textShadow: "none !important"
  }
});

class Valoracion extends React.Component {
  state = {
    disabled: true,
    rating: 0,
    opinion: "",
    star_name: "estrella"
  };

  handleCancelar() {
    if (window.getCookie("protecDatosCookie")) {
      this.props.updateScreen("chat");
    } else {
      this.props.updateScreen("initProtecdatos");
    }
  }

  changeRating(newRating, name) {
    this.setState({
      rating: newRating,
      disabled: false
    });
  }

  handleChange(event) {
    this.setState({ opinion: event.target.value });
  };

  handleForm = event => {
    event.preventDefault();
    if (window.getCookie("protecDatosCookie")) {
      this.props.updateScreen("chat");
    } else {
      this.props.updateScreen("initProtecdatos");
    }
    this.props.submitForm({
      event_id: "valoracion",
      data: {
        rating: this.state.rating,
        opinion: this.state.opinion,
        wantAnswer: window.getCookie("protecDatosCookie") ? true : false
      }
    });
  };

  hoverOverStar(e) {
    if (this.props.language === "va") {
      let title_star = ""
      try {
        let html_starclass = document.getElementsByClassName('star-ratings')
        title_star = html_starclass[0]["title"]
      } catch (error) {}
      if (title_star.indexOf('1') !== -1) {
        this.setState({ star_name: "estrella", });
      } else {
        this.setState({ star_name: "estrela", });
      }
    } else {
      this.setState({ star_name: "estrella", });
    }
  }

  render() {
    const { classes } = this.props;

    return (
      <form
        className={classes.container}
        noValidate
        autoComplete="off"
        onSubmit={this.handleForm}
      >
        <Typography className={classes.titles} color="inherit" component="p">
          {textos.askStars[this.props.selected_language]}
        </Typography>
        <div className={classes.divRating} onMouseOver={this.hoverOverStar.bind(this)}>
          <StarRatings
            rating={this.state.rating}
            changeRating={this.changeRating.bind(this)}
            numberOfStars={5}
            name="rating"
            starDimension="30px"
            starSpacing="7px"
            starRatedColor="#c8102e"
            starEmptyColor="rgb(203, 211, 227)"
            starHoverColor="#337ab7"
            typeOfWidget={this.state.star_name}
          />
        </div>
        <div className={classes.gensMolt}>
          <Typography
            className={classes.gens}
            component="p"
          >
            {textos.nada[this.props.selected_language]}
          </Typography>
          <div style={{ flex: 1 }} />
          <Typography
            className={classes.molt}
            component="p"
          >
            {textos.mucho[this.props.selected_language]}
          </Typography>
        </div>
        <Typography className={classes.opinion} color="inherit" component="p">
          {textos.askOpinion[this.props.selected_language]}
        </Typography>
        <TextField
          id="standard-name"
          placeholder={textos.opinionPlaceholder[this.props.selected_language]}
          multiline
          rows="3"
          className={classes.textField}
          onChange={this.handleChange.bind(this)}
          value={this.state.opinion}
          margin="normal"
          variant="outlined"
          inputProps={{
            maxlength: 4000,
            className: classes.input
          }}
        />
        <div className={classes.divDown}>
          <Button
            className={classes.button}
            color="primary"
            variant="contained"
            onClick={this.handleCancelar.bind(this)}
          >
            {textos.cancelar[this.props.selected_language]}
          </Button>
          <div style={{ flex: 1 }} />
          <Button
            className={classes.button}
            color="primary"
            variant="contained"
            type="submit"
            disabled={this.state.disabled}
          >
            Enviar
          </Button>
        </div>
      </form>
    );
  }
}


const mapStateToProps = state => {
  return {
    selected_language: state.selected_language,
    windowState: state.windowState
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateScreen: (screen) => dispatch(actions.updateScreen(screen))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Valoracion));
