export function storeItem(key, item){
    sessionStorage.setItem(key, JSON.stringify(item));
};

export function getValueFromSessionStored(key){
    return JSON.parse(sessionStorage.getItem(key));
};

export function messagesStored(){
    if (getValueFromSessionStored("messages")){
        return getValueFromSessionStored("messages");
    }
    else return [];
};

export function cleanStorage(key){
    sessionStorage.removeItem(key);
};