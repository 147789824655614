import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { reducer, initialState } from './store/reducer'
import Chat from './App';
import './index.css'
import { formulario } from './validations.js'
import { messagesStored, storeItem } from "./Session";
import { getTimeStamp } from './MyChatList';

console.log("v4")

let tab_form_bot = "comprador"
let before_values = {}
function validatorFunction() {
  console.log("Funcion Validar");
  // Mira el tab activo de la barra de navegacion
  let children = navbar.children;
  for (var i = 0; i < children.length; i++) {
    let classes = children[i].classList
    if (classes.contains("active")) {
      let element_a = children[i].firstElementChild
      let aux_arr = element_a.href.split('#')
      let new_tab = aux_arr[aux_arr.length - 1]
      tab_form_bot = new_tab.toLowerCase()
      break
    }
  };
  console.log("Actual: " + tab_form_bot);
  // No repetir si pincha en el mismo tab
  let contenido = formulario[tab_form_bot];
  console.log(contenido)
  //Estamos en una tab que no hay nada que validar
  if (!contenido) {
    return
  }
  let response = [];
  Object.keys(contenido).forEach(function (key) {
    let value = document.getElementById(key).value;
    //si es mismo valor que se ha validado antes no se muestra el mensaje
    if (before_values[key] && value === before_values[key]) {
      console.log("valor repetido")
      return
    }
    before_values[key] = value
    let response_aux = contenido[key](value, tab_form_bot)
    if (response_aux) {
      response.push(response_aux);
    }
  });
  if (response.length !== 0) {
    if (window.mobileCheck()) {
      window.Chat.minimize();
      window.Minimized.increment();
      let m = messagesStored();
      let leng = m.length;
      for (var msg = 0; msg < response.length; msg++) {
        let message_back = {
          text: response[msg].answer,
          type: response[msg].type,
          data: response[msg].data,
          isUser: false,
          key: leng++,
          date: getTimeStamp(),
        };
        m = m.concat([message_back]);
      }
      window.Chat.updateMessagesOut(m);
      storeItem("messages", m);
    } else {
      window.Chat.maximize();
      window.MyChatList.handleResponse(response);
    }
  }
}


const navbar = document.getElementById("navegacion");
if (navbar){
  //Comprador
  let nif_comprador = document.getElementById("nifComprador");
  nif_comprador.addEventListener('blur', validatorFunction, false);
  let input_participacion_comprador = document.getElementById("inputParticipacion");
  input_participacion_comprador.addEventListener('blur', validatorFunction, false);
  //Vendedor
  let nif_vendedor = document.getElementById("nifVendedor");
  nif_vendedor.addEventListener('blur', validatorFunction, false);
  //Presentador
  let input_participacion_vendedor = document.getElementById("nifPresentador");
  input_participacion_vendedor.addEventListener('blur', validatorFunction, false);
  //Caractecnicas
  let matriculacoche = document.getElementById("matriculacoche");
  matriculacoche.addEventListener('blur', validatorFunction, false);
  let matriculamoto = document.getElementById("matriculamoto");
  matriculamoto.addEventListener('blur', validatorFunction, false);
  let matriculaBarco = document.getElementById("matriculaBarco");
  matriculaBarco.addEventListener('blur', validatorFunction, false);
}


const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducer, initialState, composeEnhancers(
  applyMiddleware(thunk)
));


const app = (
  <Provider store={store}>
    <Chat />
  </Provider>
);


function changeFavicon() {
  let icon = document.getElementById('favicon');
  icon.href = process.env.REACT_APP_REST_HOST_LOCAL + "/get_avatar";
}

async function getBackground() {
  changeFavicon()
  let response = await fetch(process.env.REACT_APP_REST_HOST_LOCAL + "/global");
  let responseContent = await response.json()
  let elem = document.getElementById('back');
  document.title = responseContent.params.titleName;
  elem.style.backgroundImage = `url(${responseContent.params.background})`;
  elem.style.backgroundRepeat = "no-repeat";
  elem.style.backgroundPositionX = "center";
}

if (process.env.REACT_APP_IS_LOCAL) {
  getBackground();
}

console.log("version framebot 2.4.4 await")
ReactDOM.render(app, document.getElementById('widget'));
