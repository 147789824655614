import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Fab from "@material-ui/core/Fab";
import Send from "@material-ui/icons/Send";

const styles = theme => ({
  sendButton: {
    display: "flex !important",
    justifyContent: "center !important",
    margin: "auto !important",
    color: "white !important",
    borderRadius: "50% !important",
    backgroundColor: theme.palette.secondary.light + " !important",
    transition: "background-color 0.1s !important",
    "&:disabled": { backgroundColor: "rgb(222, 225, 229) !important", color: "white !important" },
    "&:hover": {
      backgroundColor: theme.palette.secondary.main + " !important"
    },
    backgroundImage: "none !important"
  },
  icon: {
    fontSize: "23px !important"
  }
});

class SendButton extends React.PureComponent {
  render() {
    const { classes } = this.props;
    return (
      <Fab
        className={classes.sendButton}
        disabled={this.props.disabled}
        variant="round"
        type="submit"
        disableFocusRipple={true}
        disableRipple={true}
      >
        <Send className={classes.icon}/>
      </Fab>
    );
  }
}

SendButton.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SendButton);
